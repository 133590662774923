var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    ref: "swiper",
    staticClass: "swiper",
    class: _vm.isMobile && 'swiper-wap'
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, [_c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    attrs: {
      "src": require('@images/game-swiper1.png')
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    attrs: {
      "src": require('@images/game-swiper1.png')
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    attrs: {
      "src": require('@images/game-swiper1.png')
    }
  })])]), _c('div', {
    staticClass: "swiper-pagination"
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };