import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
import { isAddress } from "ethers";
import noZoom from "./assets/js/noZoom";
import cookie from "js-cookie";
import crypto from 'crypto-browserify';
import ChangeLanguageButton from "@components/ChangeLanguageButton.vue";
const walletVuex = createNamespacedHelpers("wallet");
noZoom();
export default {
  components: {
    ChangeLanguageButton
  },
  provide() {
    return {
      reload: this.reload
    };
  },
  computed: {
    ...walletVuex.mapState(["setting", "isMobile", "connect", "address", "isLang"]),
    route() {
      showScreen;
      return this.$route.path;
    }
  },
  watch: {
    connect: {
      async handler(val) {},
      immediate: true
    },
    isLang: {
      async handler(val) {
        this.mTab = [{
          label: this.$lang("HOME"),
          value: "official"
        }, {
          label: this.$lang("NODE"),
          value: "/"
        }, {
          label: this.$lang("NFT MALL"),
          value: "center"
        }, {
          label: this.$lang("DOC"),
          value: "doc"
        }, {
          label: this.$lang("个人中心"),
          value: "/profile"
        }, {
          label: this.$lang("排名"),
          value: "/ranking"
        }, {
          label: this.$lang("游戏"),
          value: "/game"
        }];
      },
      immediate: true
    }
  },
  data() {
    return {
      isLoading: false,
      isRouterAlive: true,
      menuShow: false,
      mTab: [{
        label: this.$lang("HOME"),
        value: "official"
      }, {
        label: this.$lang("NODE"),
        value: "/"
      }, {
        label: this.$lang("NFT MALL"),
        value: "/center"
      }, {
        label: this.$lang("DOC"),
        value: "doc"
      }, {
        label: this.$lang("个人中心"),
        value: "/profile"
      }, {
        label: this.$lang("排名"),
        value: "/ranking"
      }, {
        label: this.$lang("游戏"),
        value: "/game"
      }]
    };
  },
  methods: {
    ...walletVuex.mapActions(["doConnect", "doDisconnect"]),
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    generateToken(data, secretKey) {
      const header = Buffer.from(JSON.stringify({
        alg: 'HS256',
        typ: 'JWT'
      })).toString('base64');
      const payload = Buffer.from(JSON.stringify(data)).toString('base64');
      const signature = crypto.createHmac('sha256', secretKey).update(`${header}.${payload}`).digest('base64');
      return `${header}.${payload}.${signature}`;
    },
    // 解密验证函数
    verifyToken(token, secretKey) {
      const [header, payload, signature] = token.split('.');
      const expectedSignature = crypto.createHmac('sha256', secretKey).update(`${header}.${payload}`).digest('base64');
      if (expectedSignature === signature) {
        return JSON.parse(Buffer.from(payload, 'base64').toString());
      }
      throw new Error('Token is invalid or has been tampered with.');
    },
    onTab(item) {
      this.menuShow = false;
      if (item.value == 'official') {
        window.open('https://reflectionai.app/');
        return;
      } else if (item.value == 'doc') {
        window.open('https://docs.reflectionai.app/');
        return;
      } else if (item.value == 'game') {
        const secretKey = 'SecretKey123'; // 用于加密的密钥
        const data = {
          address: this.web3Wallet.address,
          timestamp: Date.now()
        }; // 要加密的数据
        var token = this.generateToken(data, secretKey);
        console.log('https://game1.reflectionai.app/?company-code=25&token=' + token);
        console.log(this.verifyToken(token, secretKey));
        window.open('https://game1.reflectionai.app/?company-code=25&token=' + token);
        return;
      } else {
        this.$router.push(item.value);
      }
    },
    //邀请码
    setInvite() {
      const route = this.$route;
      const query = route.query;
      const path = route.path;
      const invite = cookie.get("t");
      console.log(path, invite, query);
      console.log("invite: ", invite);
      if (query?.t && isAddress(query?.t)) {
        cookie.set("t", query.t);
        localStorage.setItem("t", query.t);
      }
    },
    onSkip(t) {
      this.menuShow = false;
      let targetUri = "";
      switch (t) {
        case "twitter":
          targetUri = "https://x.com/reflectionai_";
          break;
        case "telegram":
          targetUri = "https://t.me/reflection_ai";
          break;
      }
      if (targetUri) window.open(targetUri);
    },
    disConnect() {
      this.doDisconnect();
    }
  },
  async created() {
    // console.log("this.web3Wallet", this.web3Wallet);
    this.web3Wallet && this.web3Wallet.initWeb3Modal();
    const walletConnected = localStorage.getItem("WALLET_CONNECTED");
    if (walletConnected == "true" || walletConnected == true) {
      await this.doConnect(true);
    }
  },
  mounted() {
    let timer = setTimeout(() => {
      clearTimeout(timer);
      timer = null;
      this.setInvite();
    }, 200);
  }
};