import "core-js/modules/esnext.iterator.map.js";
import { createNamespacedHelpers } from "vuex";
import vailcode from "@utils/errcode";
import moment from "moment";
import { formatDefaultNumber, formatRectCoinAmount } from "@utils";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  computed: {
    ...walletVuex.mapState(["isMobile", "connect", "address"])
  },
  data() {
    return {
      recordList: [],
      logLoading: false,
      logHasMore: false,
      page: 1,
      poolTotalRect: 0
    };
  },
  watch: {
    address: {
      handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.loadTotalPoolData();
    this.onLoadData(1);
  },
  methods: {
    formatDefaultNumber,
    formatRectCoinAmount,
    checkScroll() {
      const scrollHeight = this.$refs.myScroll.scrollHeight;
      const scrollTop = this.$refs.myScroll.scrollTop;
      const clientHeight = this.$refs.myScroll.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        // 接近底部时触发加载
        if (this.logHasMore && !this.logLoading) {
          let page = this.condition.page;
          this.onLoadData(page + 1);
        }
      }
    },
    async onLoadData(page) {
      if (this.logLoading) {
        return;
      }
      this.logLoading = true;
      this.page = page;
      let params = {
        page: this.page,
        pageSize: 20,
        pool_type: 'C'
      };
      let rs = await this.$http.post("pool/logs", params);
      vailcode(rs, () => {
        rs.data.list.map(item => {
          item.showDateFormat = moment(item.created_at).format('yyyy-MM-DD HH:mm:ss');
        });
        if (rs.data.pageNo == 1) {
          this.recordList = rs.data.list;
        } else {
          this.recordList = this.recordList.concat(rs.data.list);
        }
        if (rs.data.totalPage > rs.data.pageNo) {
          this.logHasMore = true;
        } else {
          this.logHasMore = false;
        }
        this.logLoading = false;
      }, () => {
        this.logLoading = false;
      });
    },
    loadTotalPoolData() {
      this.$http.post("pool", {
        pool_type: 'C'
      }).then(data => {
        this.poolTotalRect = formatRectCoinAmount(data.data[0].total_amount);
      });
    }
  }
};