import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: _vm.isMobile && 'wap',
    attrs: {
      "id": "app"
    }
  }, [_c('div', {
    staticClass: "route"
  }, [!_vm.isMobile ? _c('div', {
    staticClass: "route-main"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('img', {
    attrs: {
      "src": require('@images/logo1.png')
    }
  })]), _c('div', {
    staticClass: "tabs"
  }, _vm._l(_vm.mTab, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "mTab",
      class: item.value == _vm.$route.path && 'active',
      on: {
        "click": function ($event) {
          return _vm.onTab(item);
        }
      }
    }, [_c('span', [_vm._v(" " + _vm._s(item.label) + " ")])]);
  }), 0), _c('div', {
    staticStyle: {
      "flex-grow": "1"
    }
  }), _c('ChangeLanguageButton'), _vm.address ? [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/profile');
      }
    }
  }, [_c('img', {
    staticClass: "avatar",
    attrs: {
      "src": require('@images/avatar.png')
    }
  }), _c('div', {
    staticClass: "address-title"
  }, [_vm._v(" " + _vm._s(_vm._f("fmt_address")(_vm.address, 12)) + " ")])]), _c('div', {
    staticClass: "disconnect",
    on: {
      "click": _vm.disConnect
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "fill": "#fff",
      "d": "M8.333 1a.333.333 0 0 1 .334.333V2a.333.333 0 0 1-.334.333H2.667v11.334h5.666a.334.334 0 0 1 .334.333v.667a.333.333 0 0 1-.334.333H2.667a1.334 1.334 0 0 1-1.332-1.267l-.002-.066V2.333A1.333 1.333 0 0 1 2.6 1.002L2.667 1zm3.04 3.431 2.863 2.862a1 1 0 0 1 .045 1.366l-.045.048-2.862 2.862a.333.333 0 0 1-.472 0l-.471-.471a.333.333 0 0 1 0-.472l1.959-1.96H5.667a.333.333 0 0 1-.334-.333v-.666a.333.333 0 0 1 .334-.334h6.723l-1.959-1.96a.333.333 0 0 1 0-.47l.471-.472a.334.334 0 0 1 .472 0"
    }
  })])])] : [_c('div', {
    staticClass: "content-button-wrapper",
    staticStyle: {
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "connect-button-common connect",
    on: {
      "click": _vm.doConnect
    }
  }, [_vm._v(_vm._s(_vm.$lang(`CONNECT WALLET`)))])])]], 2) : _c('div', {
    staticClass: "route-main-wap"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('img', {
    attrs: {
      "src": require('@images/logo1.png')
    }
  })]), _c('ChangeLanguageButton'), _vm.address ? [_c('div', {
    staticClass: "address-title",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/profile');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm._f("fmt_address")(_vm.address, 8)) + " ")]), _c('div', {
    staticClass: "disconnect",
    on: {
      "click": _vm.disConnect
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "fill": "#fff",
      "d": "M8.333 1a.333.333 0 0 1 .334.333V2a.333.333 0 0 1-.334.333H2.667v11.334h5.666a.334.334 0 0 1 .334.333v.667a.333.333 0 0 1-.334.333H2.667a1.334 1.334 0 0 1-1.332-1.267l-.002-.066V2.333A1.333 1.333 0 0 1 2.6 1.002L2.667 1zm3.04 3.431 2.863 2.862a1 1 0 0 1 .045 1.366l-.045.048-2.862 2.862a.333.333 0 0 1-.472 0l-.471-.471a.333.333 0 0 1 0-.472l1.959-1.96H5.667a.333.333 0 0 1-.334-.333v-.666a.333.333 0 0 1 .334-.334h6.723l-1.959-1.96a.333.333 0 0 1 0-.47l.471-.472a.334.334 0 0 1 .472 0"
    }
  })])])] : [_c('div', {
    staticClass: "content-button-wrapper",
    staticStyle: {
      "height": "28px"
    }
  }, [_c('div', {
    staticClass: "connect-button-common connect",
    on: {
      "click": _vm.doConnect
    }
  }, [_vm._v(_vm._s(_vm.$lang(`CONNECT`)))])])], !_vm.menuShow ? _c('div', {
    staticClass: "btn-menu-humb",
    on: {
      "click": function ($event) {
        _vm.menuShow = true;
      }
    }
  }, [_c('div')]) : _vm._e(), _vm.menuShow ? _c('div', {
    staticClass: "btn-menu-close",
    on: {
      "click": function ($event) {
        _vm.menuShow = false;
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-close"
    }
  })], 1) : _vm._e()], 2), _vm.menuShow ? _c('div', {
    staticClass: "menu-wap"
  }, [_c('div', {
    staticClass: "tabs"
  }, _vm._l(_vm.mTab, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "mTab",
      class: item.value == _vm.$route.path && 'active',
      on: {
        "click": function ($event) {
          return _vm.onTab(item);
        }
      }
    }, [_c('span', [_vm._v(" " + _vm._s(item.label) + " ")])]);
  }), 0), _c('div', {
    staticClass: "socials"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.onSkip('twitter');
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-lfg"
    }
  })], 1), _c('div', {
    staticClass: "btn telegram",
    on: {
      "click": function ($event) {
        return _vm.onSkip('telegram');
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-telegram"
    }
  })], 1)])]) : _vm._e()]), _vm.isRouterAlive ? [_c('layout')] : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };