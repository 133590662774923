import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { createNamespacedHelpers } from "vuex";
import ComputeView from "@components/ComputeView.vue";
import StakeView from "@components/StakeView.vue";
import LayoutCenterRewardRecord from "@components/LayoutCenterRewardRecord.vue";
import Vue from "vue";
import BigNumber from "bignumber.js";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  components: {
    LayoutCenterRewardRecord,
    StakeView,
    ComputeView
  },
  computed: {
    ...walletVuex.mapState(["isMobile", 'address', "userInfo"])
  },
  data() {
    return {
      showRecordDialog: false,
      recordDialogType: 1,
      reward1: 0,
      reward2: 0,
      reward3: 0
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(newVal, oldVal) {
        console.log('user info change: ', newVal);
        if (newVal) {
          this.loadData();
        }
      }
    }
  },
  methods: {
    showRecord(type) {
      this.recordDialogType = type;
      this.showRecordDialog = true;
    },
    formatBigNum(number) {
      return BigNumber(number).div(10 ** 18).decimalPlaces(0, BigNumber.ROUND_DOWN);
    },
    async loadData() {
      try {
        if (this.userInfo == null) {
          return;
        }
        let params = {
          'owner': this.address
        };
        let rs = await Vue.prototype.$http.post("pool/info", params);
        if (rs?.data) {
          rs.data.forEach(item => {
            if (item.pool_type == 'A') {
              this.reward1 = this.formatBigNum(item.total_amount);
            } else if (item.pool_type == 'B') {
              this.reward2 = this.formatBigNum(item.total_amount);
            } else if (item.pool_type == 'C') {
              this.reward3 = this.formatBigNum(item.total_amount);
            }
          });
        }
      } catch (e) {
        setTimeout(() => {
          this.loadData();
        }, 3000);
      }
    }
  }
};