var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content-compute",
    class: _vm.isMobile && 'wap-content-compute'
  }, [_c('div', {
    staticClass: "top-line"
  }, [_c('div', {
    staticClass: "title-top"
  }, [_vm._v(_vm._s(_vm.$lang('Whole network compute pool')))]), _c('div', {
    staticClass: "title-value-1"
  }, [_vm._v(_vm._s(_vm.totalSuanLiChi))])]), _c('div', {
    staticClass: "split-line"
  }), _c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$lang('personal Total Compute')))]), _c('div', {
    staticClass: "line-two"
  }, [_c('div', {
    staticClass: "title-value-2"
  }, [_vm._v(_vm._s(_vm.ownTotalSunLi))]), _c('div', {
    staticClass: "title-value-3"
  }, [_vm._v(" = ")]), _c('div', {
    staticClass: "item-layout",
    on: {
      "click": function ($event) {
        _vm.showDialogBaseCompute = true;
      }
    }
  }, [_c('div', {
    staticClass: "title-sub-title"
  }, [_vm._v(_vm._s(_vm.$lang('Base Compute')))]), _c('div', {
    staticClass: "title-value-4"
  }, [_vm._v(_vm._s(_vm.ownBaseSunLi))])]), _c('pop-prompt-compute', {
    attrs: {
      "show": _vm.showDialogBaseCompute,
      "title": _vm.$lang('Base Compute'),
      "descs": [{
        title: _vm.$lang('Node compute'),
        value: _vm.ownNodeSunLi
      }, {
        title: _vm.$lang('Active compute'),
        value: _vm.ownCoinSunLi0
      }, {
        title: _vm.$lang('Fixed compute'),
        value: _vm.ownCoinSunLi1
      }, {
        title: _vm.$lang('Burning compute'),
        value: _vm.ownCoinSunLi2
      }, {
        title: _vm.$lang('Nft compute'),
        value: _vm.ownCoinSunLi3
      }, {
        title: _vm.$lang('空投NFT算力'),
        value: _vm.ownCoinSunLi4
      }]
    },
    on: {
      "update:show": function ($event) {
        _vm.showDialogBaseCompute = $event;
      }
    }
  }), _c('div', {
    staticClass: "title-value-3"
  }, [_vm._v(" + ")]), _c('div', {
    staticClass: "item-layout",
    on: {
      "click": function ($event) {
        _vm.showDialogInviteCompute = true;
      }
    }
  }, [_c('div', {
    staticClass: "title-sub-title"
  }, [_vm._v(_vm._s(_vm.$lang('Invited Compute')))]), _c('div', {
    staticClass: "title-value-4"
  }, [_vm._v(_vm._s(_vm.ownInviteSunLi))])]), _c('pop-prompt-compute', {
    attrs: {
      "show": _vm.showDialogInviteCompute,
      "title": _vm.$lang('Invited Compute'),
      "descs": [{
        title: _vm.$lang('Direct compute'),
        value: _vm.directInviteSunLi
      }, {
        title: _vm.$lang('Indirectly compute'),
        value: _vm.indirectlyInviteSunLi
      }]
    },
    on: {
      "update:show": function ($event) {
        _vm.showDialogInviteCompute = $event;
      }
    }
  }), _c('div', {
    staticClass: "title-value-3"
  }, [_vm._v(" + ")]), _c('div', {
    staticClass: "item-layout",
    on: {
      "click": function ($event) {
        _vm.showDialogRankingCompute = true;
      }
    }
  }, [_c('div', {
    staticClass: "title-sub-title"
  }, [_vm._v(_vm._s(_vm.$lang('Ranking rewards')))]), _c('div', {
    staticClass: "title-value-4"
  }, [_vm._v(_vm._s(_vm.ownRankingSunLi))])]), _c('pop-prompt-compute', {
    attrs: {
      "show": _vm.showDialogRankingCompute,
      "title": _vm.$lang('Ranking rewards'),
      "descs": [{
        title: _vm.$lang('Ranking rewards'),
        value: _vm.ownRankingSunLi
      }, {
        title: _vm.$lang('Remaining ranking reward amount'),
        value: _vm.ownRemainRankingSunli
      }],
      "other-info": _vm.$lang('Ranking reward upper limit = personal basic computing power * 10')
    },
    on: {
      "update:show": function ($event) {
        _vm.showDialogRankingCompute = $event;
      }
    }
  })], 1), _c('div', {
    staticClass: "split-line"
  }), _c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$lang('max Total Compute')))]), _c('div', {
    staticClass: "line-two"
  }, [_c('div', {
    staticClass: "title-value-2"
  }, [_vm._v(_vm._s(_vm.ownTotalSunLiMax))]), _c('div', {
    staticClass: "title-value-3"
  }, [_vm._v(" = ")]), _c('div', {
    staticClass: "item-layout"
  }, [_c('div', {
    staticClass: "title-sub-title-2"
  }, [_vm._v(_vm._s(_vm.$lang('Base Compute')))]), _c('div', {
    staticClass: "title-value-4"
  }, [_vm._v(_vm._s(_vm.ownBaseSunLiMax))])]), _c('div', {
    staticClass: "title-value-3"
  }, [_vm._v(" + ")]), _c('div', {
    staticClass: "item-layout"
  }, [_c('div', {
    staticClass: "title-sub-title-2"
  }, [_vm._v(_vm._s(_vm.$lang('Invited Compute')))]), _c('div', {
    staticClass: "title-value-4"
  }, [_vm._v(_vm._s(_vm.ownInviteSunLiMax))])]), _c('div', {
    staticClass: "title-value-3"
  }, [_vm._v(" + ")]), _c('div', {
    staticClass: "item-layout"
  }, [_c('div', {
    staticClass: "title-sub-title-2"
  }, [_vm._v(_vm._s(_vm.$lang('Ranking rewards')))]), _c('div', {
    staticClass: "title-value-4"
  }, [_vm._v(_vm._s(_vm.ownRankingSunLiMax))])])]), _c('div', {
    staticClass: "split-line"
  }), _c('div', {
    staticClass: "bottom-tips"
  }, [_c('div', [_vm._v(_vm._s(_vm.$lang('Remaining ranking reward amount:')))]), _c('div', {
    staticClass: "bottom-tips-value"
  }, [_vm._v(_vm._s(_vm.ownRemainRankingSunli))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };