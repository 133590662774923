import "core-js/modules/esnext.iterator.map.js";
import SwiperGameHeader from "@components/SwiperGameHeader.vue";
import Vue from "vue";
import GameMiniRecord from "@components/GameMiniRecord.vue";
import crypto from 'crypto-browserify';
import { createNamespacedHelpers } from "vuex";
import { showCommonTipsDialog } from "@components/CommonDialog";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  components: {
    GameMiniRecord,
    SwiperGameHeader
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "connect", "address"])
  },
  data() {
    return {
      signDays: [],
      gameList: [],
      showRecordDialog: false
    };
  },
  watch: {
    address: {
      handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.getSignData();
  },
  methods: {
    getGameData() {
      Vue.prototype.$http.post("game/list").then(data => {
        console.log(data);
        if (data.code == 0) {
          this.signDays = data.data;
          this.getSignedData();
        }
      }).catch(err => {});
    },
    getSignData() {
      Vue.prototype.$http.post("checkin/configs").then(data => {
        console.log(data);
        if (data.code == 0) {
          this.signDays = data.data;
          this.getSignedData();
        }
      }).catch(err => {});
    },
    getSignedData() {
      Vue.prototype.$http.post("checkin/status", {
        owner: this.address
      }).then(data => {
        console.log(data);
        if (data.code == 0) {
          console.log(data.dat);
          if (data.data) {
            // count 1
            // cycle 1
            // updated_at "2025-01-25T12:45:54.000Z"

            let count = data.data.count % 30;
            if (count == 0) {
              count = 30;
            }
            let lastSignDays = data.data.updated_at;
            // lastSignDays = '2025-01-24T12:45:54.000Z';
            //如果更新时间和当天时间 , 对比日期一样, 当天即签到过.
            let today = new Date();
            let lastSignDate = new Date(lastSignDays);

            // 格式化日期为 yyyy-mm-dd
            function formatDate(date) {
              let year = date.getFullYear();
              let month = (date.getMonth() + 1).toString().padStart(2, '0');
              let day = date.getDate().toString().padStart(2, '0');
              return `${year}-${month}-${day}`;
            }
            let formattedToday = formatDate(today);
            let formattedLastSignDate = formatDate(lastSignDate);

            // 如果更新时间和当天时间一样
            if (formattedToday === formattedLastSignDate) {
              console.log("当天已签到");
              this.signDays = this.signDays.map(item => {
                if (item.day <= count) {
                  item.signed = true;
                } else {
                  item.signed = false;
                }
                item.showSign = false;
                return item;
              });
            } else {
              // 如果更新时间和当天时间不一样，显示签到按钮，次数+1天
              console.log("显示签到按钮，次数+1天");
              this.signDays = this.signDays.map(item => {
                if (item.day <= count && count < 30) {
                  item.signed = true;
                } else {
                  item.signed = false;
                }
                if (count == 30 && item.day == 1) {
                  item.showSign = true;
                } else if (item.day == count + 1) {
                  item.showSign = true;
                } else {
                  item.showSign = false;
                }
                return item;
              });
            }
          }
        }
      }).catch(err => {});
    },
    generateToken(data, secretKey) {
      const header = Buffer.from(JSON.stringify({
        alg: 'HS256',
        typ: 'JWT'
      })).toString('base64');
      const payload = Buffer.from(JSON.stringify(data)).toString('base64');
      const signature = crypto.createHmac('sha256', secretKey).update(`${header}.${payload}`).digest('base64');
      return `${header}.${payload}.${signature}`;
    },
    onTab(index) {
      if (index == 0) {
        const secretKey = 'SecretKey123'; // 用于加密的密钥
        const data = {
          address: this.address,
          timestamp: Date.now()
        }; // 要加密的数据
        var token = this.generateToken(data, secretKey);
        window.open('https://game1.reflectionai.app/?company-code=25&token=' + token);
        return;
      } else {
        //this.$router.push(item.value);
      }
    },
    startSign() {
      this.$loading.show();
      Vue.prototype.$http.post("checkin", {
        owner: this.address
      }).then(data => {
        console.log(data);
        if (data.code == 0) {
          this.getSignedData();
        }
      }).catch(err => {}).finally(() => {
        this.$loading.hide();
      });
    },
    showSignRules() {
      showCommonTipsDialog(this.$lang('签到规则'), this.$lang('1. 签到周期不设固定天数限制。用户每完成30次签到，系统将自动重置签到周期。<br>' + '2. 若用户签到中断，之前的签到记录将被保留，用户可在上线后继续从中断前的签到数开始。<br>' + '3. 每次签到所获得的奖励将直接计入用户的个人中心账户余额，并生成相应的“签到奖励”记录。'), false).then(() => {}).catch(() => {});
    }
  }
};