import axios from "axios";
import Vue from "vue";
import loadConfig from "@Web3WalletConfig/config";
import md5 from 'md5'
import crypto from 'crypto-browserify'
import {Buffer} from 'buffer'

const Axios = axios.create({
    timeout: 100000, // 请求超时时间
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
});
const publicKey = loadConfig.api_public_key
const concatString = loadConfig.api_http_key
const storeId = loadConfig.api_store_id

Axios.defaults.baseURL = loadConfig.http_host;
// 添加请求拦截器
Axios.interceptors.request.use(
    (config) => {
        if (config.method.toLowerCase() == 'post') {
            // let data = {...config.data, storeId, _time: timeStamp(), _appType: "h5", _uuid: userDevice()}
            let data = {...config.data, _time: timeStamp()}

            // console.log('=====>' + config.url + '\n' + '=====>' + JSON.stringify(data))

            // 数据加密
            const data_token = generateEncryptedData(data)
            const paramsData = {data_token, time: timeStamp()}
            // 获取签名
            const access_tonken = signatureStr(paramsData)
            data = {...paramsData, access_tonken}

            config.data = data
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

/* 响应拦截器 */
Axios.defaults.validateStatus = (status) => {
    // 根据状态码返回结果
    if (/^(2|3)\d{2}$/.test(status)) {
        return true;
    } else {
        console.error(`Error: ${status} error`);
    }
};
// 添加响应拦截器
Axios.interceptors.response.use(
    (res) => {
        // 对响应数据做点什么
        return res.data;
    },
    (err) => {
        /* 没网 */
        if (!window.navigator.onLine) {
            //跳转到断网页面
            Vue.toasted.success(`${Vue.prototype.$lang("Error: Network exception")}`);
            return;
        } else {
            return Promise.reject(err.data);
        }
    }
);


function timeStamp() {
    return Math.floor(Date.now() / 1000)
}

function signatureStr(params) {
    let result = {...params}
    let sortedKeys = Object.keys(result).sort()
    let resultStr = ''
    for (let i = 0; i < sortedKeys.length; i++) {
        let key = sortedKeys[i]
        let value = result[key]
        if (value !== undefined && value !== null && value !== '') {
            resultStr += `${key}|${value}`
        }
    }
    resultStr += concatString
    let signature = md5(resultStr)
    return signature
}

const generateEncryptedData = (encryptedData) => {
    // console.error(encryptedData)
    encryptedData = Buffer.from(JSON.stringify(encryptedData)).toString('base64')
    // console.error(encryptedData, ' ', encryptedData.length)
    let encrypted = ''

    function extracted(str) {
        try {
            const encryptData = crypto.publicEncrypt(
                {
                    key: publicKey,
                    padding: crypto.constants.RSA_PKCS1_OAEP_PADDING,
                },
                Buffer.from(str)
            )
            let encrypted1 = encryptData.toString('hex')
            return encrypted1
        } catch (e) {
            console.error('encrypt error: ', str)
            console.error(e)
        }
    }

    //测试　128　长度，小于128　长度　大于128长度
    // console.log('start check')
    // function testlength(str){
    //     let result = extracted(str)
    //     console.log(result,' ',result.length)
    // }
    // console.log(testlength('eyJ'))
    // console.log(testlength('eyJwYWdlIjoxLCJwYWdl'))
    // console.log(testlength('eyJwYWdlIjoxLCJwYWdlU2l6ZSI6MTUsIm93bmVyIjoiMHg3MEE0NE'))
    // console.log(testlength('eyJwYWdlIjoxLCJwYWdlU2l6ZSI6MTUsIm93bmVyIjoiMHg3MEE0NEJlYzQyMzE2MzU1MjFDMzgwOUZBYzE4QjlC'))
    // console.log(testlength('eyJwYWdlIjoxLCJwYWdlU2l6ZSI6MTUsIm93bmVyIjoiMHg3MEE0NEJlYzQyMzE2MzU1MjFDMzgwOUZBYzE4QjlCNzkyRGE2MzE4IiwiYXNzZXRzX3R5cGUiOiIiLCJ'))
    // console.log(testlength('eyJwYWdlIjoxLCJwYWdlU2l6ZSI6MTUsIm93bmVyIjoiMHg3MEE0NEJlYzQyMzE2MzU1MjFDMzgwOUZBYzE4QjlCNzkyRGE2MzE4IiwiYXNzZXRzX3R5cGUiOiIiLCJf'))
    // console.log(testlength('eyJwYWdlIjoxLCJwYWdlU2l6ZSI6MTUsIm93bmVyIjoiMHg3MEE0NEJlYzQyMzE2MzU1MjFDMzgwOUZBYzE4QjlCNzkyRGE2MzE4IiwiYXNzZXRzX3R5cGUiOiIiLCJfff'))
    // console.log('end check')

    const ENCRYPT_LEN = 128
    for (let encryptPos = 0; encryptPos < encryptedData.length; encryptPos += ENCRYPT_LEN) {
        const str = encryptedData.substr(encryptPos, ENCRYPT_LEN)
        // console.error(str, ' ', str.length)
        for (let i = 0; i < 5; i++) {
            let encrypted1 = extracted(str)
            // console.error(encrypted1, ' ', encrypted1.length)
            if (encrypted1.length == 512) {
                // console.error("kjsaflkjslaialsifjijlkjaiojkl")
                encrypted += encrypted1
                break
            }
        }
    }
    // encrypted = extracted(encryptedData)
    // console.error(encrypted, ' ', encrypted.length)

    return encrypted
}
const userDevice = () => {
    let userAgent = navigator.userAgent
    // return userAgent
    // 创建正则表达式来匹配系统信息
    try {
        const deviceMatch = userAgent.match(/\(([^)]+)\)/)
        if (deviceMatch) {
            const deviceInfo = deviceMatch[1].split(';')[0].trim()
            return deviceInfo
        }
        return userAgent.split(' ')[0]
    } catch (e) {
        return userAgent
    }
}

// 对axios的实例重新封装成一个plugin ,方便 Vue.use(xxxx)
export default {
    install: (Vue) =>
        Object.defineProperty(Vue.prototype, "$http", {
            value: Axios,
        }),
    Axios,
};
