import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
import NftMallItem from "@components/NftMallItem.vue";
import { showCommonTipsDialog, showRectBalanceNotEnoughDialog } from "@components/CommonDialog";
import Vue from "vue";
import web3Wallet from "@web3Wallet";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  components: {
    NftMallItem
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "connect", "address"])
  },
  data() {
    return {
      recordList: [],
      page: 1,
      totalPage: 1,
      showDialog: false,
      selectedNftItem: null,
      nftSalePrice: 0,
      filterType: 'sale_time',
      descType: 'desc'
    };
  },
  mounted() {
    this.loadMallNftList();
    this.initUserCenterData();
  },
  methods: {
    onPageChange(page) {
      console.log(page);
      this.page = page;
      this.loadMallNftList();
    },
    onItemPurchageClick(nftItem) {
      this.selectedNftItem = nftItem;
      this.showDialog = true;
      this.initUserCenterData();
    },
    onPurchaseCancel() {
      this.showDialog = false;
      this.selectedNftItem = null;
    },
    onPurchaseConfirm(inputNumber) {
      this.showDialog = false;
      this.$loading.show();
      //sign message
      let message = 'buy nft action';
      web3Wallet.signMessage(message).then(signed => {
        let params = {
          owner: this.address,
          seller: this.selectedNftItem.owner,
          count: inputNumber,
          signed: signed,
          signedMsg: 'buy nft action'
        };
        return Vue.prototype.$http.post("nft/buy", params);
      }).then(data => {
        this.$loading.hide();
        if (data.code == 0) {
          return showCommonTipsDialog(this.$lang('success'), this.$lang('Purchase successful'));
        } else if (data.code == -10) {
          return showRectBalanceNotEnoughDialog().then(() => {
            this.$router.push('/profile');
          });
        } else {
          return showCommonTipsDialog(this.$lang('hint'), data.message);
        }
      }).then(() => {
        this.loadMallNftList();
      }).catch(() => {}).finally(() => {
        this.selectedNftItem = null;
        this.$loading.hide();
      });
    },
    filterData(filterType, descType) {
      this.page = 1;
      this.filterType = filterType;
      this.descType = descType;
      this.loadMallNftList();
    },
    loadMallNftList() {
      this.$loading.show();
      let params = {
        page: this.page,
        pageSize: this.isMobile ? 12 : 24,
        orderColumn: this.filterType,
        orderDirection: this.descType
      };
      Vue.prototype.$http.post("nft/list", params).then(data => {
        console.log(data);
        this.recordList = data.data.list;
        this.totalPage = data.data.totalPage;
      }).catch(err => {}).finally(() => {
        this.$loading.hide();
      });
    },
    initUserCenterData() {
      if (!this.address) {
        return;
      }
      this.$loading.show();
      let params = {
        owner: this.address
      };
      Vue.prototype.$http.post("nft/info", params).then(data => {
        // console.log(data)

        data = data.data;
        this.nftSalePrice = data.price;
      }).catch(e => {}).finally(() => {
        this.$loading.hide();
      });
    }
  }
};