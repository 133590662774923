var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "stake-record-button",
    on: {
      "click": _vm.showDialog
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Record')) + " ")]), _c('pop-dialog', {
    attrs: {
      "show": _vm.showRecordDialog,
      "height": "auto",
      "width": _vm.isMobile ? '90vw' : '500px'
    },
    on: {
      "closeFn": function ($event) {
        _vm.showRecordDialog = false;
      }
    }
  }, [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "pop__intro"
  }, [_vm._v(_vm._s(_vm.$lang("Stake Record")))]), _c('div', {
    staticClass: "stake-type-tabs"
  }, [_c('div', {
    staticClass: "stake-type-item",
    class: _vm.stakeType == 2 ? 'stake-type-item-active' : '',
    on: {
      "click": function ($event) {
        return _vm.switchStakeType(2);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Burn')) + " ")]), _c('div', {
    staticClass: "stake-type-item",
    class: _vm.stakeType == 1 ? 'stake-type-item-active' : '',
    on: {
      "click": function ($event) {
        return _vm.switchStakeType(1);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Fixed')) + " ")]), _c('div', {
    staticClass: "stake-type-item",
    class: _vm.stakeType == 0 ? 'stake-type-item-active' : '',
    on: {
      "click": function ($event) {
        return _vm.switchStakeType(0);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Active')) + " ")]), _c('div', {
    staticClass: "stake-type-item",
    class: _vm.stakeType == 3 ? 'stake-type-item-active' : '',
    on: {
      "click": function ($event) {
        return _vm.switchStakeType(3);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Activity')) + " ")])]), _c('div', {
    staticClass: "pop-log-list"
  }, [_c('div', {
    ref: "myScroll",
    staticClass: "pop-log-box",
    class: _vm.isMobile && 'wap',
    on: {
      "scroll": _vm.checkScroll
    }
  }, [!_vm.recordList || _vm.recordList.length <= 0 ? [!_vm.logLoading ? _c('EmptyLayout') : _vm._e()] : _vm._e(), _vm._l(_vm.recordList, function (item, index) {
    return _c('div', {
      staticClass: "pop-log-item"
    }, [_c('div', {
      staticClass: "pop-log-item-line"
    }, [item.stake_type === 3 ? _c('div', {
      staticClass: "log-item-coin log-item-coin--flex"
    }, [_c('div', {
      staticClass: "log-item-main"
    }, [_vm._v(_vm._s(item.hashrate_format))]), _c('div', {
      staticClass: "log-item-desc"
    }, [_vm._v(_vm._s(_vm.$lang('Additional Burn Power')))])]) : _c('div', {
      staticClass: "log-item-coin"
    }, [_vm._v(" " + _vm._s(item.amount_format) + "$ RECT ")]), item.can_unlock ? _c('div', {
      staticClass: "pop-item-actions"
    }, [!_vm.unStakeLoading[item.id] ? _c('div', {
      staticClass: "button-unstake",
      on: {
        "click": function ($event) {
          return _vm.doUnStake(item);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$lang('Redeem')) + " ")]) : _c('div', [_c('svg-icon', {
      attrs: {
        "name": "icon-refresh",
        "spin": ""
      }
    })], 1)]) : _vm._e()]), item.stake_type == 3 ? _c('div', {
      staticClass: "pop-log-item-line"
    }, [_c('div', [_vm._v(" " + _vm._s(_vm.$lang('Burn Time')) + " ")]), _c('div', [_vm._v(" " + _vm._s(item.stake_time_format) + " ")])]) : _c('div', {
      staticClass: "pop-log-item-line"
    }, [_c('div', [_vm._v(" " + _vm._s(_vm.$lang('Stake Time')) + " ")]), _c('div', [_vm._v(" " + _vm._s(item.stake_time_format) + " ")])]), item.stake_type == 1 ? _c('div', {
      staticClass: "pop-log-item-line"
    }, [_c('div', [_vm._v(" " + _vm._s(_vm.$lang('Expiration Time')) + " ")]), _c('div', [_vm._v(" " + _vm._s(item.unlock_time_format) + " ")])]) : _vm._e()]);
  }), _vm.logLoading ? _c('div', {
    staticClass: "loadingList"
  }, [_c('div', {
    staticClass: "loading-list"
  }, _vm._l(3, function (_) {
    return _c('div');
  }), 0)]) : _vm._e()], 2)]), _c('div', {
    staticClass: "close-btn",
    on: {
      "click": function ($event) {
        _vm.showRecordDialog = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang('Close')))])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };