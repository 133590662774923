import { createNamespacedHelpers } from "vuex";
import web3Wallet from "@web3Wallet";
import PopPromptTwo from "@components/popPromptTwo.vue";
import vailcode from "@utils/errcode";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  components: {
    PopPromptTwo
  },
  data() {
    return {
      // allowAction: false,
      checked: false,
      popTipShow: false
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "address", "userInfo"]),
    disableClick() {
      if (this.userInfo) {
        return false;
      }
      return true;
    }
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(newVal, oldVal) {
        console.log('user info change: , auto brush ', newVal);
        if (newVal) {
          if (newVal.user?.auto_burn == 1) {
            this.checked = true;
          } else {
            this.checked = false;
          }
        }
      }
    }
  },
  methods: {
    ...walletVuex.mapActions(["doConnect", "refreshUserInfo"]),
    onToogle(e) {
      console.log(e);
      let checked = e.target.checked;
      e.preventDefault();
      try {
        const message = 'auto_burn_status_change';
        web3Wallet.signMessage(message).then(async signed => {
          let params = {
            owner: this.address,
            status: checked ? 1 : 0,
            signedMsg: message,
            signed: signed
          };
          let rs = await this.$http.post("stake/autoburn", params);
          vailcode(rs, async () => {
            this.refreshUserInfo();
          });
        }).then(data => {
          console.log(data);
          //
        }).catch(ee => {
          if (this.userInfo.user?.auto_burn == 1) {
            this.checked = true;
          } else {
            this.checked = false;
          }
        });
      } catch (e) {
        if (this.userInfo.user?.auto_burn == 1) {
          this.checked = true;
        } else {
          this.checked = false;
        }
      }
    }
  }
};