var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', {
    attrs: {
      "show": _vm.showRecordDialog,
      "height": "auto",
      "is-close": false,
      "width": _vm.isMobile ? '90vw' : '780px'
    },
    on: {
      "closeFn": _vm.closeDialog
    }
  }, [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "pop__intro"
  }, [_vm._v(_vm._s(_vm.$lang("積分记录")))]), _c('div', {
    staticClass: "pop-log-list"
  }, [_c('div', {
    ref: "myScroll",
    staticClass: "pop-log-box",
    class: _vm.isMobile && 'wap',
    on: {
      "scroll": _vm.checkScroll
    }
  }, [!_vm.recordList || _vm.recordList.length <= 0 ? [!_vm.logLoading ? _c('EmptyLayout') : _vm._e()] : _vm._e(), _vm._l(_vm.recordList, function (item, index) {
    return _c('div', {
      staticClass: "reward-record-item"
    }, [_c('div', {
      staticClass: "reward-record-item-left"
    }, [_c('div', {
      staticClass: "reward-record-item-title"
    }, [_vm._v(" " + _vm._s(item.showAmount) + " $ RECT ")]), _c('div', {
      staticClass: "reward-record-item-sub"
    }, [_vm._v(" " + _vm._s(item.showDateFormat) + " ")])]), _c('div', {
      staticClass: "reward-record-item-right"
    }, [item.type == 'play' ? [_vm._v(_vm._s(_vm.$lang('消耗')))] : _vm._e(), item.type == 'win' ? [_vm._v(_vm._s(_vm.$lang('获得')))] : _vm._e()], 2)]);
  }), _vm.logLoading ? _c('div', {
    staticClass: "loadingList"
  }, [_c('div', {
    staticClass: "loading-list"
  }, _vm._l(3, function (_) {
    return _c('div');
  }), 0)]) : _vm._e()], 2)]), _c('div', {
    staticClass: "close-btn",
    on: {
      "click": function ($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang('Close')))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };